import { regex } from "@/utils/regex";
import useFetch from "../../../../../hooks/useFetch";
import {
  Delivery,
  ResultItem,
} from "../../../../../interface/orderManageInterface";
import { copyFileSync } from "fs";

interface Props {
  isSelect: boolean;
  item: ResultItem;
  orderList: ResultItem[];
  idx: number;
  setOrderList: React.Dispatch<React.SetStateAction<ResultItem[]>>;
}

export const ItemProductOrderInfo = ({
  idx,
  isSelect,
  item,
  orderList,
  setOrderList,
}: Props) => {
  const [deliveryCompany] = useFetch(
    `deliveryCompany${item.market}`,
    `/MarketOrder/GetDeliveryCompany?market=${item.market}`
  );

  const replaceNotInt = /[^A-Za-z0-9]/gi;

  const selectDeliveryCompany = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newArr = [...orderList];

    if (e.target.value === "") {
      newArr[idx] = {
        ...newArr[idx],
        deliveryCompanyCode: "",
        deliveryCompanyName: "",
      };
    } else {
      newArr[idx] = {
        ...newArr[idx],
        deliveryCompanyCode: deliveryCompany[parseInt(e.target.value)].code,
        deliveryCompanyName: deliveryCompany[parseInt(e.target.value)].name,
      };
    }
    setOrderList(newArr);
  };

  const inputOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value.replace(replaceNotInt, "");
    const newArr = [...orderList];
    newArr[idx] = {
      ...newArr[idx],
      [name]: value,
    };
    setOrderList(newArr);
  };

  const inputOrderListEditValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newArr = [...orderList];

    const FindDeliveryCode = DeliveryCode?.filter((Company) =>
      Company.code.includes(value.slice(0, 2))
    )[0];
    const FindDeliveryObject = deliveryCompany?.filter((item: any) => {
      return item?.name?.includes(FindDeliveryCode?.name);
    })[0];

    FindDeliveryCode
      ? (newArr[idx] = {
        ...newArr[idx],
        [name]: value,
        deliveryCompanyCode: FindDeliveryObject?.code,
        deliveryCompanyName: FindDeliveryObject?.name,
      })
      : (newArr[idx] = {
        ...newArr[idx],
        [name]: value,
      });
    setOrderList(newArr);
  };

  return (
    <td className="width15">
      <div className={`collect-inner-1 noScroll ${isSelect && "selectedItem"}`}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex-col OrderManageOrderInfotable"
        >
          <input
            value={orderList[idx].overseaOrderNumber}
            onChange={inputOrderListEditValue}
            name="overseaOrderNumber"
            className="OrderInfotableItemInput mb-1"
            placeholder="소싱마켓 상품 주문번호"
          />
          <input
            onChange={inputOrderListEditValue}
            value={
              orderList[idx].purchasePriceOrigin === 0
                ? ""
                : orderList[idx].purchasePriceOrigin
            }
            name="purchasePriceOrigin"
            className="OrderInfotableItemInput mb-1"
            placeholder="구입금액(신고금액)"
          />
          <input
            onChange={inputOrderListEditValue}
            value={orderList[idx].trackingNumber}
            name="trackingNumber"
            className="OrderInfotableItemInput mb-1"
            placeholder="트래킹 번호"
          />
          <select
            value={deliveryCompany?.findIndex(
              (item: Delivery) =>
                item.name === orderList[idx].deliveryCompanyName
            )}
            onChange={selectDeliveryCompany}
            className="OrderInfotableItemInput mb-1"
          >
            <option value="">택배사를 선택해 주세요</option>
            {deliveryCompany?.map((item: Delivery, idx: number) => (
              <option key={idx} value={idx}>
                {item.name}
              </option>
            ))}
          </select>
          <input
            onChange={inputOrderListEditValue}
            onBlur={inputOnBlur}
            value={orderList[idx].invoiceNumber}
            name="invoiceNumber"
            className="OrderInfotableItemInput mb-1"
            placeholder="국내 운송장번호"
          />
          <input
            onChange={inputOrderListEditValue}
            value={
              orderList[idx].shipmentFee === 0 ? "" : orderList[idx].shipmentFee
            }
            name="shipmentFee"
            className="OrderInfotableItemInput"
            placeholder="국제 운송료"
          />
        </div>
      </div>
    </td>
  );
};

const DeliveryCode = [
  { name: "CJ", code: ["68", "58", "54", "57", "50", "59"], index: 0 },
  { name: "한진", code: ["51"], index: 1 },
  { name: "우체국", code: ["60"], index: 2 },
  { name: "롯데", code: ["31", "25"], index: 3 },
];
