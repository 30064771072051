import axios from "axios";
import { CrawlKeywordType } from "../component/TagSetting/KeywordSearching";

export const getCrawledItemList = async (keyword: string) => {
  const relList: string[] = [];
  const autoList: string[] = [];

  try {
    const coupangRes = await axios.get(
      `https://center.allttam.com/api/coupangkeyword?keyword=${keyword}`
    );

    // coupangRes.data가 존재하고, rel이 배열이면 forEach 실행
    if (coupangRes.data && Array.isArray(coupangRes.data.rel)) {
      coupangRes.data.rel.forEach((item: CrawlKeywordType) => {
        if (!relList.includes(item.keyword)) {
          relList.push(item.keyword);
        }
      });
    }

    // coupangRes.data가 존재하고, auto가 배열이면 forEach 실행
    if (coupangRes.data && Array.isArray(coupangRes.data.auto)) {
      coupangRes.data.auto.forEach((item: CrawlKeywordType) => {
        if (!autoList.includes(item.keyword)) {
          autoList.push(item.keyword);
        }
      });
    }
  } catch (e) {
    console.error('Coupang API error:', e);
  }

  try {
    const naverRes = await axios.get(
      `https://center.allttam.com/api/naverkeyword?keyword=${keyword}`
    );

    // naverRes.data가 존재하고, rel이 배열이면 forEach 실행
    if (naverRes.data && Array.isArray(naverRes.data.rel)) {
      naverRes.data.rel.forEach((item: CrawlKeywordType) => {
        if (!relList.includes(item.keyword)) {
          relList.push(item.keyword);
        }
      });
    }

    // naverRes.data가 존재하고, auto가 배열이면 forEach 실행
    if (naverRes.data && Array.isArray(naverRes.data.auto)) {
      naverRes.data.auto.forEach((item: CrawlKeywordType) => {
        if (!autoList.includes(item.keyword)) {
          autoList.push(item.keyword);
        }
      });
    }
  } catch (e) {
    console.error('Naver API error:', e);
  }

  // try {
  //   const coupangRes = await axios.get(
  //     `https://center.allttam.com/api/coupangkeyword?keyword=${keyword}`
  //   );
  //   coupangRes.data.rel.forEach((item: CrawlKeywordType) => {
  //     if (relList.includes(item.keyword)) return;
  //     else return relList.push(item.keyword);
  //   });

  //   coupangRes.data.auto.forEach((item: CrawlKeywordType) => {
  //     if (autoList.includes(item.keyword)) return;
  //     else return autoList.push(item.keyword);
  //   });
  // } catch (e) { }

  // const naverRes = await axios.get(
  //   `https://center.allttam.com/api/naverkeyword?keyword=${keyword}`
  // );
  // naverRes.data.rel.forEach((item: CrawlKeywordType) => {
  //   if (relList.includes(item.keyword)) return;
  //   else return relList.push(item.keyword);
  // });

  // naverRes.data.auto.forEach((item: CrawlKeywordType) => {
  //   if (autoList.includes(item.keyword)) return;
  //   else return autoList.push(item.keyword);
  // });

  return {
    rel: relList,
    auto: autoList,
  };
};
