import { ModalBg } from "@/components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@/components/ModalLayout/ModalInnerTop";
import useFetch from "@/hooks/useFetch";
import { useInfoWindow } from "@/hooks/useInfoWindow";
import {
  Delivery,
  ResultItem,
  SendInvoiceRes,
} from "@/interface/orderManageInterface";
import { orderManage } from "@/utils/functions/postApi";
import { useState } from "react";
import { SendInvoiceResultModal } from "./SendInvoiceResultModal";
import useModal from "@/hooks/useModal";

const genUpdateData = (selected: ResultItem[], orderList: ResultItem[]) => {
  const selectedId = selected.map((item) => item.idx);
  return orderList.filter((list) => selectedId.includes(list.idx));
};

const OrderManageTableItemInvoiceModal = ({
  item,
  orderList,
  setOrderList,
  idx,
  setClose,
  selectedOrder,
}: any) => {
  const [deliveryCompany] = useFetch(
    `deliveryCompany${item.market}`,
    `/MarketOrder/GetDeliveryCompany?market=${item.market}`
  );
  const { openModal } = useModal();
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const replaceNotInt = /[^A-Za-z0-9]/gi;

  const inputOrderListEditValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newArr = [...orderList];

    const FindDeliveryCode = DeliveryCode?.filter((Company) =>
      Company.code.includes(value.slice(0, 2))
    )[0];
    const FindDeliveryObject = deliveryCompany?.filter((item: any) => {
      return item?.name?.includes(FindDeliveryCode?.name);
    })[0];

    FindDeliveryCode
      ? (newArr[idx] = {
        ...newArr[idx],
        [name]: value,
        deliveryCompanyCode: FindDeliveryObject?.code,
        deliveryCompanyName: FindDeliveryObject?.name,
      })
      : (newArr[idx] = {
        ...newArr[idx],
        [name]: value,
      });

    setOrderList(newArr);
  };

  const selectDeliveryCompany = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newArr = [...orderList];
    if (e.target.value === "") {
      newArr[idx] = {
        ...newArr[idx],
        deliveryCompanyCode: "",
        deliveryCompanyName: "",
      };
    } else {
      newArr[idx] = {
        ...newArr[idx],
        deliveryCompanyCode: deliveryCompany[parseInt(e.target.value)].code,
        deliveryCompanyName: deliveryCompany[parseInt(e.target.value)].name,
      };
    }
    setOrderList(newArr);
  };
  const inputOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value.replace(replaceNotInt, "");

    const newArr = [...orderList];
    newArr[idx] = {
      ...newArr[idx],
      [name]: value,
    };

    setOrderList(newArr);
  };

  const openWindow = useInfoWindow();
  const [sendInvoiceRes, setSendInvoiceRes] = useState<SendInvoiceRes[]>([]);

  const confirmSendInvoice = async () => {
    try {
      setSendInvoiceRes([]);
      const updateData = genUpdateData(selectedOrder, orderList);

      if (selectedOrder.length === 0) throw new Error();
      const hasEmptyValues = updateData.some(
        (item) => item.deliveryCompanyCode === "" || item.invoiceNumber === ""
      );

      if (hasEmptyValues) {
        openWindow("송장 정보를 입력해 주세요.\n송장 정보가 없습니다.");
        return;
      } else {
        setInvoiceLoading(true);
        let res: SendInvoiceRes[] = [];
        let failList: any[] = [];

        for (const [idx, item] of updateData.entries()) {
          const sendRes = await orderManage.sendInvoice({
            deliveryCompanyCode: item.deliveryCompanyCode,
            deliveryCompanyName: item.deliveryCompanyName,
            idx: item.idx,
            invoiceNumber: item.invoiceNumber,
            sequence: idx,
          });

          sendRes.code === 401 && failList.push(sendRes);
          res = [...res, sendRes.data];
        }

        if (failList.length !== 0 && failList[0].code === 401) {
          openWindow(
            `주문상태를 확인해 주세요.\n스마트 스토어는 배송 완료 시 송장 전송을 할 수 없습니다.`
          );
          return;
        }

        let FailCount = 0;

        res?.map((content) => {
          if (!content?.isSuccess) FailCount += 1;
          return FailCount === 0
            ? openWindow(
              `송장 전송을 성공 했습니다.\n상품 ${selectedOrder.length}건`
            )
            : (openModal(<SendInvoiceResultModal sendInvoiceRes={res} />),
              openWindow(
                `송장 전송을 완료 했습니다.\n상품 ${selectedOrder.length - FailCount
                }건 성공 / ${FailCount}건 실패`
              ));
        });
      }
      setInvoiceLoading(false);
    } catch {
      setClose();
      setInvoiceLoading(false);
      // openWindow("상품을 선택해 주세요.\n선택된 상품이 없습니다.");
    } finally {
      setClose();
      setInvoiceLoading(false);
    }
  };

  return (
    <ModalBg onClick={() => null}>
      <ModalInnerTop>
        <div className="modal-header">
          <h5 className="modal-title d-flex align-items-center">송장 전송</h5>
          <button
            onClick={() => setClose(false)}
            type="button"
            className="btn-close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <select
              value={deliveryCompany?.findIndex(
                (item: Delivery) =>
                  item?.name === orderList[idx]?.deliveryCompanyName
              )}
              onChange={selectDeliveryCompany}
              className="OrderInfotableItemInputMobile mb-3 w-100 mt-3"
            >
              <option value="">택배사를 선택해 주세요</option>
              {deliveryCompany?.map((item: Delivery, idx: number) => (
                <option key={idx} value={idx}>
                  {item.name}
                </option>
              ))}
            </select>
            <input
              onChange={inputOrderListEditValue}
              onBlur={inputOnBlur}
              value={orderList[idx]?.invoiceNumber}
              name="invoiceNumber"
              className="OrderInfotableItemInputMobile mb-2 w-100"
              placeholder="국내 운송장번호"
            />
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                <button
                  className="cancel-btn save-btn"
                  onClick={confirmSendInvoice}
                >
                  전송
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalInnerTop>
    </ModalBg>
  );
};

export default OrderManageTableItemInvoiceModal;

const DeliveryCode = [
  { name: "CJ", code: ["68", "58", "54", "57", "59", "50"], index: 0 },
  { name: "한진", code: ["51"], index: 1 },
  { name: "우체국", code: ["60"], index: 2 },
  { name: "롯데", code: ["31", "25"], index: 3 },
];
